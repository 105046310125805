.infoModal {
    p {
        margin-block-start: 0.5em;
        margin-block-end: 0.5em;
    }
    .buttonRow {
        text-align: center;
        margin: auto;
        margin-top: 15px;
        margin-bottom: 15px;
    }
}