.confirm {
    --padding-top: 15px;
    --padding-bottom: 15px;
    --padding-start: 15px;
    --padding-end: 15px;
    &__title {
        font-size: 14px;
        text-align: center;
        margin-bottom: 15px;
    }
    &__btnRow {
        text-align: center;
        margin: auto;
        margin-top: 15px;
    }
    &__spinner {
        margin: auto;
        text-align: center;
        margin-bottom: 10px;
    }
    &__item {
        --padding-start: 0;
    }
}
.mr-3 {
    margin-right: 15px;
}