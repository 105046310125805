.tariffContent {
    --padding-top: 15px;
    --padding-bottom: 15px;
    &__item {
        margin-bottom: 15px;
        padding-left: 15px;
        background-repeat: 15px;
        font-size: 14px;
        display: flex;
        align-items: center;
        span {
            line-height: 1;
        }
        ion-icon {
            font-size: 24px;
            opacity: .8;
            margin-right: 17px;
        }
    }
    &__phone {
        .item-input {
            border-top: 1px solid #ccc;
        }
    }
    &__message {
        display: flex;
        justify-content: center;
        padding: 20px;
    }
}

.messageSuccess {
    width: 100%;
    .button {
        float: right;
    }
}