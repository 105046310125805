.ionPage {
    width: 100%;
    position: fixed;
    bottom: 0;
    border-top: 1px solid #d7d8da;
    box-sizing: border-box;
    background-color: #fff;
    ion-item {
        --padding-start: 10px;
        --inner-padding-end: 10px;
    }
}
.ionFabButton {
    right: 0;
    position: absolute;
    top: -40px;
    transform: translateY(-50%);
}
.inputIcon {
    width: 28px;
    height: 28px;
    background-color: #ed576b;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-right: 12px;
    margin-left: 6px;
}
.settingContainer {
    font-size: 14px;
    ion-row {
        justify-content: space-between;
    }
}
.order {
    display: flex;
    align-items: center;
    ion-checkbox {
        margin-right: 10px;
    }
    ion-label {
        padding-top: 2px;
    }
}
.additionally {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    text-align: right;
    ion-icon {
        font-size: 20px;
        margin-right: 10px;
    }
    &:hover {
        cursor: pointer;
        background-color: rgb(233, 233, 233);
    }
}
.orderList {
    padding-top: 0;
    padding-bottom: 0;
}
.driverRow {
    margin: 0;
    padding: 0;
    li {
        list-style: none;
        display: flex;
        align-items: center;
        padding-left: 5px;
        padding-right: 5px;
        font-size: 15px;
    }
}
.orderDelete {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #d7d8da;
    padding: 8px 10px;
    position: relative;
    ion-label {
        padding-left: 5px;
        padding-right: 5px;
    }
    ion-icon {
        font-size: 22px;
        opacity: .6;
        margin-right: 15px;
    }
    .down {
        position: absolute;
        right: 5px;
        margin: 0;
    }
}
.orderDriver {
    padding: 8px 10px;
    display: flex;
    align-items: center;
    ion-label {
        padding-left: 5px;
        padding-right: 5px;
    }
    ion-icon {
        font-size: 22px;
        opacity: .6;
        margin-right: 15px;
    }
    ion-spinner {
        margin-right: 10px;  
    }
}
.getOrders {
    border-bottom: 1px solid #d7d8da;
    &__item {
        display: flex;
        align-items: center;
        padding: 5px 10px;
        ion-label {
            padding-left: 5px;
            padding-right: 5px;
        }
        ion-icon {
            font-size: 22px;
            opacity: .6;
            margin-right: 15px;
        }
        .inputIcon {
            margin-right: 15px;
            width: 24px;
            height: 24px;
            font-size: 14px;
        }
    }
}
.legsInfo {
    background-color: rgba(255, 255, 255, 0.7);
    color: #333333;
    max-width: 76px;
    width: 100%;
    box-sizing: border-box;
    padding: 2px 8px;
    border-radius: 3px;
    font-size: 12px;
    position: absolute;
    top: -68px;
    left: 5px;
    font-weight: 700;
    &__item {
        display: flex;
        align-items: center;
        line-height: 1.6;
        ion-icon {
            margin-right: 3px;
            font-size: 14px;
        }
    }
}