.searchItem {
    &__icon {
        margin-top: 14px;
    }
}
.searchHeader {
    .in-toolbar {
        --min-height: 40px;
        .title-default {
            font-size: 16px;
            font-weight: 600;
        }
        ion-buttons {
            font-size: 26px;
        }
        .doneIcon {
            margin-right: 8px;
            font-size: 32px;
            color: #ed576b;
        }
    }
}
.searchBar {
    padding-top: 0;
    .searchbar-clear-button {
        display: block;
    }
    .searchbar-input {
        -webkit-padding-start: 36px;
        padding-inline-start: 36px;
        -webkit-padding-end: 36px;
        padding-inline-end: 36px;
    }
    .searchbar-search-icon {
        left: 8px;
    }
}

.searchAdress {
    overflow-y: scroll;
    height: 100%;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 6px;
    &__item {
        min-height: 42px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding-left: 8px;
        padding-right: 8px;
        padding-top: 6px;
        padding-bottom: 6px;
        border-bottom: 1px solid #ccc;
        font-size: 14px;
        &:last-child {
            border-bottom: 0;
        }
    }
    &__text {
        display: block;
        width: 100%;
    }
    &__city {
        opacity: .8;
        display: block;
        font-size: 13px;
    }
}

.searchBar {
    padding: 6px 12px 12px;
    position: relative;
    &__icon {
        position: absolute;
        top: 14px;
        font-size: 20px;
        color: #333333;
        &.search {
            left: 20px;
        }
        &.close {
            right: 20px;
        }
    }
    &__input {
        width: 100%;
        box-sizing: border-box;
        border: 1px solid #ccc;
        padding: 5px;
        padding-left: 32px;
        padding-right: 32px;
        height: 36px;
        box-sizing: border-box;
        border-radius: 3px;
        &__loading {
            width: 100%;
            box-sizing: border-box;
            border: 1px solid #ccc;
            padding: 5px;
            padding-left: 32px;
            padding-right: 32px;
            height: 36px;
            box-sizing: border-box;
            border-radius: 3px;
            background-image: url('/assets/loading16x16.gif');
            background-position: right 30px center;
            background-repeat: no-repeat;
        }
        &__loading_he {
            width: 100%;
            box-sizing: border-box;
            border: 1px solid #ccc;
            padding: 5px;
            padding-left: 32px;
            padding-right: 32px;
            height: 36px;
            box-sizing: border-box;
            border-radius: 3px;
            background-image: url('/assets/loading16x16.gif');
            background-position: left 30px center;
            background-repeat: no-repeat;
        }
    }
    
    &__option {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background-color: #fff;
        font-size: 1rem;
    }
    &__list {
        list-style: none;
        padding: 0;
        padding-top: 10px;
        padding-bottom: 10px;
        margin: 0;
        overflow-y: scroll;
        height: 86vh;
    }
    &__item {
        padding: 4px 12px;
        transition: all .3s ease;
        &:hover {
            cursor: pointer;
            background-color: rgb(233, 233, 233);
        }

    }
    &__city {
        font-size: 14px;
        color: #666666;
    }
}