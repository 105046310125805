.tariffCarousel {
    background-color: #fff;
    padding: 10px;
}
.tariffContainer {
    background-color: #fff;
    display: flex;
    padding: 5px;
    .tariffBox {
        max-width: 33.33333%;
        width: 100%;
        text-align: center;
        margin: 5px;
        min-height: 62px;
    }
}
.tariffBox {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    font-size: 14px;
    padding: 3px;
    border-radius: 8px;
    &__icon {
        color: #ed576b;
        display: block;
        font-size: 20px;
    }
    &__title {
        display: block;
        width: 100%;
        margin: 0;
        font-weight: 600;
    }
    &__price {
        display: block;
        width: 100%;
        margin: 0;
        font-size: 14px;
        font-weight: 700;
    }
    &:hover{
        cursor: pointer;
        background-color: rgb(233, 233, 233);
    }
}
.ionContentTarriff {
    min-height: 94px;
}
.spinner{
    margin: auto;
    padding-top: 3px;
    padding-bottom: 2px;
}
.spinner ion-spinner{
    margin-left: 40%;
    margin-right: 5px;
}