ion-content ion-toolbar {
  --background: transparent;
}

.menuBurger {
  width: 32px;
  height: 32px;
  background: #ed576b;
  color: #fff;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 3px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.25);

  /* Status bar height on iOS 11.0 */
  transform: translateY(constant(safe-area-inset-top));
  /* Status bar height on iOS 11+ */
  transform: translateY(env(safe-area-inset-top));
}
