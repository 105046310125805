.pinIcon {
    width: 26px;
    height: 26px;
    background-color: #ed576b;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    transform: translateY(-28px);
    transition: all .5s ease;
    &__tooltip {
        position: absolute;
        top: -100%;
        width: 162px;
        text-align: center;
        background-color: #0000006e;
        color: #fff;
        border-radius: 3px;
        font-size: 13px;
        padding: 3px;
        cursor: pointer;
    }
    &__item {
        width: 40%;
        height: 40%;
        background-color: #fff;
        border-radius: 50%;
    }
    &::after {
        content: '';
        position: absolute;
        width: 2px;
        height: 14px;
        background-color: #ed576b;
        bottom: -14px;
    }
    &::before {
        content: '';
        position: absolute;
        width: 8px;
        height: 5px;
        box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, .6);
        bottom: -16px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.3);
    }
}