.mr-3 {
    margin-right: 15px;
}
.errorMessage {
    font-size: 12px;
    line-height: 1.2;
    display: block;
    margin-top: 5px;
    margin-bottom: 5px;
}
