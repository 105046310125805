.modalListItem {
    .bigIcon {
        margin-inline-end: 16px;
    }
    ion-item {
        &:active,
        &:focus {
            .arrowRight {
                transition: all 0.5s ease;
                transform: scale(1.3);
            }
        }
        &:hover {
            cursor: pointer;
            --ion-item-background: rgb(233, 233, 233);
        }
    }
}

.infoModal{
    ion-item {
        &:hover{
            cursor: pointer;
            --ion-item-background:rgb(233, 233, 233);
        }
    }
}
ion-buttons:hover{
    cursor: pointer;
}